import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IoMedalSharp } from 'react-icons/io5';
import { toggleSidebar } from '../../store/themeConfigSlice';
import { FaUniversity, FaUserTie } from 'react-icons/fa';
import { IRootState } from '../../store';
import { IoPeopleCircleOutline } from 'react-icons/io5';
import IconCaretsDown from '../Icon/IconCaretsDown';

const Sidebar = () => {
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const navigations = [
        {
            title: 'PT dan Prodi',
            icon: FaUniversity,
            url: '/statistic/',
        },
        {
            title: 'Akreditasi',
            icon: IoMedalSharp,
            url: '/statistic/akreditasi/',
        },
    ];

    return (
        <div className={semidark ? 'dark' : ''}>
            <nav
                className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${semidark ? 'text-white-dark' : ''}`}
            >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <NavLink to="/statistic/" className="main-logo flex items-center shrink-0">
                            <img className="w-28 ml-[5px] flex-none" src="https://kelembagaan.kopertis7.go.id/statistic/assets/images/logo.svg" alt="logo" />
                        </NavLink>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown duotone={false} className="m-auto rotate-90" />
                        </button>
                    </div>
                    <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
                        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    {navigations.map((nav, index) => (
                                        <li key={index} className="nav-item">
                                            <NavLink to={nav.url} className="group">
                                                <div className="flex items-center">
                                                    <nav.icon />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t(nav.title)}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;
