import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { IRootState } from '../../store';
import { toggleSidebar } from '../../store/themeConfigSlice';
import { Tab } from '@headlessui/react';
import IconMenu from '../Icon/IconMenu';

const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const navigations = ['/statistic/', '/statistic/akreditasi'];

    useEffect(() => {
        const currentIndex = navigations.indexOf(location.pathname);
        if (currentIndex !== -1) setSelectedIndex(currentIndex);
    }, [location]);

    const handleLogoClick = () => {
        setSelectedIndex(0);
    };

    return (
        <header className={`z-40 ${themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
            <div className="shadow-sm bg-primary-light">
                <div className="relative layout flex items-center px-5 dark:bg-black">
                    <div className="horizontal-logo gap-2 flex lg:hidden w-full items-center ltr:mr-2 rtl:ml-2">
                        <div className="sm:hidden">
                            <button
                                type="button"
                                className="collapse-icon flex-none"
                                onClick={() => {
                                    dispatch(toggleSidebar());
                                }}
                            >
                                <IconMenu className="w-5 h-5" duotone={false} />
                            </button>
                        </div>
                        <NavLink to="/statistic/" onClick={handleLogoClick} className="main-logo mx-auto sm:mx-0 flex items-center shrink-0">
                            <img className="w-24 sm:w-36 ltr:-ml-1 rtl:-mr-1 inline" src="https://kelembagaan.kopertis7.go.id/statistic//assets/images/logo.svg" alt="logo" />
                        </NavLink>
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className="sm:flex horizontal-menu hidden">
                                {navigations.map((path, index) => (
                                    <Tab as={Fragment} key={path}>
                                        {({ selected }) => (
                                            <NavLink
                                                to={path}
                                                className={`${selected ? 'text-primary-dark-light !outline-none before:!w-full' : ''
                                                    } before:inline-block font-bold relative -mb-[2.5px] flex items-center p-5 py-5 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[2.5px] before:w-0 before:bg-primary before:transition-all before:duration-700 hover:text-primary-dark-light hover:before:w-full`}
                                            >
                                                {index === 0 && 'PT dan Prodi'}
                                                {index === 1 && 'Akreditasi'}
                                            </NavLink>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                        </Tab.Group>
                        <Link to="https://kelembagaan.kopertis7.go.id/login" target="_blank" className='sm:ml-auto my-2 '>
                            <button type="button" className="btn btn-primary btn-sm sm:btn">
                                Log in
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
