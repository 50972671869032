import { lazy } from 'react';
import Basic from '../pages/DataTables/Basic';

const Error = lazy(() => import('../components/Error'));
const PerguruanTinggi = lazy(() => import('../pages/Pages/PerguruanTinggi'));
const BadanPenyelenggara = lazy(() => import('../pages/Pages/BadanPenyelenggara'));
const Aktreditasi = lazy(() => import('../pages/Pages/Akreditasi'));
const Jabatan = lazy(() => import('../pages/Pages/Jabatan'));

const routes = [
    {
        path: '/statistic',
        element: <PerguruanTinggi />,
    },
    {
        path: '*',
        element: <Error />,
        layout: 'blank',
    },
    {
        path: '/statistic',
        element: <PerguruanTinggi />,
    },
    {
        path: '/statistic/akreditasi',
        element: <Aktreditasi />,
    },
];

export { routes };
